import { PortableText } from '@portabletext/react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ElementVideo from 'src/components/ElementVideo/ElementVideo';
import imgFallback from 'src/utils/image';

function Hero({ hero: { title, description, links, heroImage, logo, video } }) {
  console.log(heroImage);
  return (
    <Container className="pb-5 pt-9">
      <Row className={heroImage === null ? 'pt-lg-9 pb-lg-6' : 'pt-lg-7'}>
        <Col xs={12} md={8} lg={6} xl={{ span: 5, offset: 1 }} className="d-flex flex-column justify-content-start">
          <h1 className="h2 px-0">{title}</h1>
          {logo && logo?.image?.asset?.gatsbyImageData?.images?.sources?.length !== 0 && (
            <div className="my-3 px-0">
              <GatsbyImage alt={logo?.alt ?? ''} style={{ maxWidth: '350px' }} image={logo?.image?.asset?.gatsbyImageData ?? imgFallback} />
            </div>
          )}
        </Col>
        <Col xs={12} md={8} lg={6} xl={5} className="mt-lg-0 d-flex flex-column justify-content-start mt-5">
          {description && (
            <div className="text-muted">
              <PortableText value={description} />
            </div>
          )}

          {links?.length > 0 && (
            <Row>
              {links.map((link) => (
                <Col key={link._key} xs="auto">
                  <AnchorLink to={link.url} className="btn-link-primary text-uppercase mt-3">
                    {link.title}
                  </AnchorLink>
                </Col>
              ))}
            </Row>
          )}
        </Col>
      </Row>
      {video && (
        <Row>
          <Col xs={12}>
            <ElementVideo
              url={video?.asset?.url}
              type={video?.asset?.mimeType}
              className="video-size-narrow"
              wrapperClassName="video-hue-primary"
            />
          </Col>
        </Row>
      )}
      {heroImage && heroImage?.image?.asset?.gatsbyImageData?.images?.sources?.length !== 0 && (
        <Row className="mt-4">
          <Col xs={12} className="mt-5">
            <GatsbyImage alt={heroImage?.alt ?? ''} className="w-full" image={heroImage?.image?.asset?.gatsbyImageData ?? imgFallback} />
          </Col>
        </Row>
      )}
    </Container>
  );
}

Hero.propTypes = {
  hero: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.arrayOf(PropTypes.shape({})),
    links: PropTypes.arrayOf(PropTypes.shape({})),
    logo: PropTypes.shape({}),
    heroImage: PropTypes.shape({}),
  }).isRequired,
};

export default Hero;
