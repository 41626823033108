import PropTypes from 'prop-types';
import React from 'react';
import './ElementVideo.scss';

import { useEffect, useState } from 'react';

const getWindowDimensions = () => {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  // Handle the case where window object is not available (for SSR)
  return {
    width: 0,
    height: 0,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowDimensions;
};

function ElementVideo({ url, title, type, className, wrapperClassName }) {
  const { width } = useWindowDimensions();
  const video = (
    <video autoPlay={width > 576} muted loop playsInline title={title} className={className}>
      <source src={url} type={type} />
    </video>
  );
  if (width > 576) {
    return <div className={wrapperClassName}>{video}</div>;
  }
  return null;
}

ElementVideo.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  wrapperClassName: PropTypes.string,
};

ElementVideo.defaultProps = {
  wrapperClassName: null,
  title: '',
};

export default ElementVideo;
